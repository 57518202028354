import React from 'react';
import { useRoutes } from 'react-router-dom';
import Home from '../pages/home';
import Dashboard from '../pages/dashboard';
import Index from '../pages/index';



const Routes = () => {
    const element = useRoutes([
        {
            path: '/',
            element: <Dashboard />,
        },
        {
            path: 'dashboard',
            element: <Home />,
        },
        {
            path: 'index',
            element: <Index />,
        },
        {
            path: 'q',
            element: <Index />,
        },

    ])
    return element
}

export default Routes;
