import React from 'react';
import { useState } from 'react';
import './login.css';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import { useSmsMutation, useLoginMutation } from '../../store/pageApi2';
import { useEffect } from 'react';
const Login = (props) => {
    const [sms] = useSmsMutation()
    const [login] = useLoginMutation()
    const [num, setNum] = useState(60)
    const [phone, setPhone] = useState('')
    const [start, setStart] = useState(false)
    const [error, setError] = useState("")
    useEffect(()=>{
        if(localStorage.getItem('token')&&localStorage.getItem('type')){
            props.setLogin(true)
            props.infoHandle(localStorage.getItem('token'),localStorage.getItem('type'))
        }
    },[])

    const countDown = () => {
        setStart(true)
        setError("")
        setTimeout(() => {
            setNum(state => {
                if (state === 1) {
                    setStart(false)
                    setNum(60)
                } else {
                    countDown()
                    return state - 1
                }
            })
            console.log(num)
        }, 1000)
    }
    const countDown2 = () => {


        sms({
            phone
        }).then(res => {

            if(res.data.rs==0){
                setError(res.data.msg)
                setNum(60);
                setStart(false)

            }else{
                countDown()
            }
            console.log(res)
        });
    }
    const phoneTest = function (value) {
        var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;    //11位手机号码正则
        if (
            !reg_tel.test(value)
        ) {
            return false
        } else {
            return true
        }
    }
    const SignupSchema = Yup.object().shape({
        type: Yup.string()
            .required('请选择来宾类别'),
        code: Yup.number()
            .required('验证码'),
        phone: Yup.string()
            .test('phone', '请输入正确的手机号码', phoneTest)
            .required('请输入手机号码'),
    });




    return (
        <div style={{
            fontWeight: '400', maxWidth: '750px', margin: '0 auto',
        }}>


            {/* <div style={{ position: 'relative' }}>
                <img src="/img/bg.png" alt="" style={{
                    backgroundSize: '100%',
                    zIndex: '-1',
                    width: "100%",
                    position: 'absolute'
                }} />
            </div> */}
            <img src="/img/login-bg-1-2.png" alt="" style={{ width: '100%' }} />
            <div className="" style={{
                padding: '0 150px', backgroundImage: "linear-gradient(rgb(217 227 237), rgb(214 224 234), rgb(219 229 238))",
                backgroundSize: '100% 100%',
            }}>
                <Formik
                    initialValues={{
                        type: '',
                        phone: '',
                        code: ''
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={values => {
                        console.log(values)
                        login({
                            phone: values.phone,
                            type: values.type,
                            code: values.code,
                        }).then(res => {
                            console.log(res)

                            if (res.data.rs === 1) {
                                localStorage.setItem("token",res.data.token)
                                localStorage.setItem("type",values.type)
                                props.infoHandle(res.data.token,values.type)
                                props.setLogin(true)

                            }else{
                                setError(res.data.msg)

                            }

                        });


                    }}
                >
                    {({ values, handleChange, handleBlur, errors, touched }) => (
                        <Form>

                            <div className="form-group form-group-login">
                                <div className='titlelogin'>身份类别 Guest Category</div>
                                <div className='jiantou_kuang'>
                                    <img src="/img/jiantou-2.png" alt="" className='jiantou-login-2' />
                                </div>

                                <select name="type"
                                    value={values.type}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.type && touched.type ?
                                        "input-error form-control form-control-login" : "form-control form-control-login"}
                                >
                                    <option value=""></option>
                                    <option value="1">参会嘉宾</option>
                                    <option value="2">参会嘉宾行程联系人</option>
                                </select>

                                {errors.type && touched.type ? (
                                    <div className='err'>{errors.type}</div>
                                ) : null}
                            </div>

                            <div className="form-group form-group-login">
                                <div className='titlelogin'>手机号 Mobile Number </div>
                                <input type="number" name="phone"
                                    value={values.phone}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setPhone(e.target.value)
                                    }}
                                    onBlur={handleBlur}
                                    className={errors.phone && touched.phone ?
                                        "input-error form-control form-control-login" : "form-control form-control-login"}
                                />
                                {errors.phone && touched.phone ? (
                                    <div className='err'>{errors.phone}</div>
                                ) : null}
                            </div>
                            <div className="form-group form-group-login" >
                                <div className='titlelogin'>验证码  Code</div>


                                <div style={{ display: 'flex' }}>
                                    <input type="number" name="code"
                                        value={values.code}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.code && touched.code ?
                                            "input-error form-control form-control-login input-group-yan" : "form-control form-control-login input-group-yan"}

                                    />
                                    {
                                        start ? <button className="btn btn-default input-groupbtn" type="button" >倒计时 {num}</button> : <button className="btn btn-default input-groupbtn" type="button" onClick={countDown2}>获取验证码</button>
                                    }
                                </div>

                                {errors.code && touched.code ? (
                                    <div className='err'>{errors.code}</div>
                                ) : null}

                                {error && error ? (
                                    <div className='err'>{error}</div>
                                ) : null}
                            </div>
                            <button type='submit' className="btn btn-default form-control form-control-login btn-submit "
                                style={{ margin: '20px 0 0px 0', color: '#fff',textTransform:"uppercase" }}
                            >登录 login</button>
                        </Form>
                    )}
                </Formik>

            </div>

            <img src="/img/login-bg-2-2.png" alt="" style={{ width: '100%' }} />
            
        </div>
    );
}

export default Login;
