import React from 'react';
import { useState } from 'react';
import './dashboard.css'
import { useRegisterMutation } from '../../store/pageApi';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useSearchParams } from 'react-router-dom';
import wx from 'weixin-js-sdk'
import { useShareMutation } from '../../store/shareApi';
import { useEffect } from 'react';

import { useTranslation } from "react-i18next";





const Dashboard = () => {
    const { t, i18n } = useTranslation();
    const [share] = useShareMutation()
    const [success, setsuccess] = useState(false)
    const [lang, setLang] = useState('zh')
    const [tijiao, settijiao] = useState(true)
    const [count] = useSearchParams()
    /*    useEffect(() => {
            const url = encodeURIComponent(document.URL);
            share(url).then(res => {
                console.log(res)
                    const { data } = res
                    wx.config({
                        debug: false,
                        appId: data.app_id,
                        timestamp: data.timestamp,
                        nonceStr: data.nonce_str,
                        signature: data.signature,
                        jsApiList: [
                            'updateAppMessageShareData',
                            'updateTimelineShareData'
                        ]
                    })
    
                    window.title2 = "2023年小鹏汽车全球合作伙伴大会报名通道";
                    window.desc2 = "通道1";
                    var imgUrl = "https://h5.aiweber.cn/images/xiaopeng_log1.jpg";
                    window.url2 = "https://xp.h5.aiweber.cn/";
                    wx.ready(() => {
                        wx.updateAppMessageShareData({
                            title: window.title2,
                            desc: window.desc2,
                            link: window.url2,
                            imgUrl: imgUrl,
                            success: function () {
                                
                            },
                            cancel: function () {
                                
                            }
                        })
    
                        wx.updateTimelineShareData({
                            title: window.title2,
                            link: window.url2,
                            imgUrl: imgUrl,
                            success: function () {
                                
                            },
                            cancel: function () {
                                
                            }
                        })
                    })
            });
        }, [])*/

    const [register, { isSuccess }] = useRegisterMutation()

    const phoneTest = function (value) {
        var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;    //11位手机号码正则
        if (
            !reg_tel.test(value)
        ) {
            return false
        } else {
            return true
        }
    }
    const nameTest = (str) => {
        //验证是否是中文

        var pattern = new RegExp("[\u4E00-\u9FA5]+");
        if (pattern.test(str)) {
            if(str.length>4){
                return false
            }
        }
        return true
    }


    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .required(t("alert2")),
        compony: Yup.string()
            .required(t("alert1")),
        phone: Yup.string()
            .test('phone', t("alert3-1"), phoneTest)
            .required(t("alert3-2")),
        arrive: Yup.string()
            .required(t("alert4")),
        back: Yup.string()
            .required(t("alert5")),

        // test_no: Yup.array().when("test_1", {
        //     is: (value) => value.length === 0,
        //     then: () => Yup.array().min(1, "请选择试乘试驾"),
        // }),
        // test_no: Yup.array().min(1, "身份证最少18位"),

        // test_1: Yup.array(),

        // idcard: Yup.string().when("type", {
        //     is: (value) => value === '1',
        //     then: () => Yup.string().required("请输入身份证号码").min(18, "身份证最少18位"),

        // }),
        // passport: Yup.string().when("type", {
        //     is: (value) => value === '2',
        //     then: () => Yup.string().required("请输入护照")

        // }),
        // stay: Yup.string().when("arrange", {
        //     is: (value) => value === '1',
        //     then: () => Yup.string()
        //         .required('请选择入住时间'),
        // }),
        // checkin: Yup.string().when("arrange", {
        //     is: (value) => value === '1',
        //     then: () => Yup.string()
        //         .required('请选择退房时间'),
        // }),
        // type: Yup.string().when("test_1", {
        //     is: (value) => value.length >= 1,
        //     then: () => Yup.string()
        //         .required('请选择证件类型'),
        // }),
    });

    const SignupSchema2 = Yup.object().shape({
        name: Yup.string()
            .required(t("alert2"))
            .test('name', '姓名最多4个字', nameTest),
        compony: Yup.string()
            .required(t("alert1")),
        phone: Yup.string()
            .test('phone', t("alert3-1"), phoneTest)
            .required(t("alert3-2")),
        arrive: Yup.string()
            .required(t("alert4")),
        back: Yup.string()
            .required(t("alert5")),
    })

    return (
        <div style={{
            backgroundImage: "linear-gradient(rgb(224, 235, 241),#e6ebf1, rgb(223, 233, 242))",
            fontWeight: '400', maxWidth: '750px', margin: '0 auto', position: 'relative',fontSize:'24px'
        }}>
            {
                lang === 'zh' ? <img src="/img/zh-5-4-2.png" alt="" style={{ width: '100%' }} /> :
                    <img src="/img/en-5-4-2.png" alt="" style={{ width: '100%' }} />
            }
            <div style={{ position: 'absolute', left: '85px', top: '800px' }}>
                <label className="radio-inline-lang radio-inline" >
                    <input
                        type="radio"
                        name="lang"
                        value='zh'
                        onChange={(e) => {
                            i18n.changeLanguage(e.target.value);
                            setLang('zh')
                        }}
                        checked={lang === 'zh'}
                    /> 简体中文
                </label>
                <label className="radio-inline" >
                    <input
                        type="radio"
                        name="lang"
                        value='en'
                        onChange={(e) => {
                            i18n.changeLanguage(e.target.value);
                            setLang('en')
                        }}
                        checked={lang !== 'zh'}
                    /> English
                </label>
            </div>
            <div className="container" style={{ padding: '0 80px', margin: '0 auto', backgroundImage: lang === 'zh' ? "linear-gradient(to right,rgb(228, 234, 243), rgb(230 235 242), rgb(225, 234, 243)" : "linear-gradient(to right,rgb(229, 237, 244), rgb(233, 236, 243), rgb(228, 237, 244)", paddingTop: '20px' }}>



                <Formik
                    initialValues={{
                        name: '',
                        compony: '',
                        phone: '',
                        a_name: '',
                        a_phone: '',
                        arrive: '',
                        back: '',
                        arrange: '',
                    }}

                    validationSchema={SignupSchema2}
                    onSubmit={values => {
                        // same shape as initial values
                        console.log(values)
                        console.log(count.get('channel') + 'channel')
                        settijiao(false)

                        register({
                            name: values.name,
                            company_name: values.compony,
                            phone: values.phone,
                            arr_at: values.arrive,
                            lev_at: values.back,
                            a_name: values.a_name,
                            a_phone: values.a_phone,
                            channel: (count.get('channel'))
                        }).then(res => {
                            if (res.data.rs) {

                                settijiao(true)
                            }else{
                                alert(res.data.msg);
                            }
                        });


                    }}
                >
                    {({ values, handleChange, handleBlur, errors, touched }) => (
                        <Form>
                            <div className="form-group">
                                <div className='dash-title1'>{t("Company")}</div>

                                <input type="input" name="compony"
                                    value={values.compony}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.compony && touched.compony ?
                                        "input-error form-control input-text" : "form-control input-text"}
                                />
                                {errors.compony && touched.compony ? (
                                    <div className='err'>{errors.compony}</div>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <div className='dash-title1'>{t("Participant")}</div>
                                <input type="input" name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.name && touched.name ?
                                        "input-error form-control input-text" : "form-control input-text"}
                                />
                                {errors.name && touched.name ? (
                                    <div className='err'>{errors.name}</div>
                                ) : null}
                            </div>

                            <div className="form-group">
                                <div className='dash-title1'>{t("Mobile")}</div>
                                <p className='xiaozi2'>{t("used1")}</p>

                                <input type="input" name="phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.phone && touched.phone ?
                                        "input-error form-control input-text" : "form-control input-text"}
                                />
                                {errors.phone && touched.phone ? (
                                    <div className='err'>{errors.phone}</div>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <div className='dash-title1'>{t("Optional1")}</div>
                                <p className='xiaozi2'>{t("used3")}</p>
                                <input type="input" name="a_name"
                                    value={values.a_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.a_name && touched.a_name ?
                                        "input-error form-control input-text" : "form-control input-text"}
                                />
                                {errors.a_name && touched.a_name ? (
                                    <div className='err'>{errors.a_name}</div>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <div className='dash-title1'>{t("Optional2")}</div>
                                <p className='xiaozi2'>{t("used2")}</p>
                                <input type="input" name="a_phone"
                                    value={values.a_phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.a_phone && touched.a_phone ?
                                        "input-error form-control input-text" : "form-control input-text"}
                                />
                                {errors.a_phone && touched.a_phone ? (
                                    <div className='err'>{errors.a_phone}</div>
                                ) : null}
                            </div>


                            {
                                lang === 'zh' ? (
                                    <>
                                        <div className="form-group">
                                            <label>{t("p1-ARRIVAL")}</label>
                                            <div className=' chooseData' style={{ display: 'flex' }}>
                                                <div className="" style={{ width: '95px' }}>2023</div>
                                                <div className="" style={{ width: '125px' }}>{t("YEAR")}</div>
                                                <div className="" style={{ width: '45px' }}>5</div>
                                                <div className="" style={{ width: '130px' }}>{t("MONTH")}</div>
                                                <div className="jiantou_kuang" >
                                                    <img src="/img/jiantou.png" alt="" className='jiantou' />
                                                    <select name="arrive"
                                                        style={{ width: '105px' }}
                                                        value={values.arrive}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.arrive && touched.arrive ?
                                                            "input-error form-control dash-form-control" : "form-control dash-form-control"}>
                                                        <option value=''></option>
                                                        <option value='24'>24</option>
                                                        <option value='25'>25</option>


                                                    </select>
                                                </div>
                                                <div className="" style={{ width: '55px', textAlign: 'right' }}>{t("DATE")}
                                                </div>
                                            </div>
                                            {errors.arrive && touched.arrive ? (
                                                <div className='err'>{errors.arrive}</div>
                                            ) : null}
                                        </div>

                                        <div className="form-group">
                                            <label>{t("RETURN")} </label>
                                            <div className=' chooseData' style={{ display: 'flex' }}>
                                                <div className="" style={{ width: '95px' }}>2023</div>
                                                <div className="" style={{ width: '125px' }}>{t("YEAR")}</div>
                                                <div className="" style={{ width: '45px' }}>5</div>
                                                <div className="" style={{ width: '130px' }}>{t("MONTH")}</div>
                                                <div className="jiantou_kuang" >
                                                    <img src="/img/jiantou.png" alt="" className='jiantou' />
                                                    <select name="back"
                                                        style={{ width: '105px' }}
                                                        value={values.back}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={errors.back && touched.back ?
                                                            "input-error form-control dash-form-control" : "form-control dash-form-control"}>
                                                        <option value=''></option>

                                                        <option value='25'>25</option>
                                                        <option value='26'>26</option>

                                                    </select>
                                                </div>
                                                <div className="" style={{ width: '55px', textAlign: 'right' }}>{t("DATE")}
                                                </div>
                                            </div>
                                            {errors.back && touched.back ? (
                                                <div className='err'>{errors.back}</div>
                                            ) : null}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="form-group">
                                            <label>{t("ARRIVAL")}</label>
                                            <div className=' chooseData' style={{ display: 'flex' }}>
                                                <div style={{ width: '140px' }}>
                                                    <div className="jiantou_kuang" style={{ width: '105px' }}>
                                                        <img src="/img/jiantou.png" alt="" className='jiantou' />
                                                        <select name="arrive"
                                                            style={{ width: '105px' }}
                                                            value={values.arrive}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={errors.arrive && touched.arrive ?
                                                                "input-error form-control dash-form-control" : "form-control dash-form-control"}>
                                                            <option value=''></option>
                                                            <option value='24'>24</option>
                                                            <option value='25'>25</option>


                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="" style={{ width: '190px' }}>{t("DATE")}
                                                </div>

                                                <div className="" style={{ width: '180px' }}>May</div>

                                                <div className="" style={{ width: '60px' }}>2023</div>


                                            </div>
                                            {errors.arrive && touched.arrive ? (
                                                <div className='err'>{errors.arrive}</div>
                                            ) : null}
                                        </div>

                                        <div className="form-group">
                                            <label>{t("RETURN")} </label>
                                            <div className=' chooseData' style={{ display: 'flex' }}>
                                                <div style={{ width: '140px' }}>
                                                    <div className="jiantou_kuang" style={{ width: '105px' }}>
                                                        <img src="/img/jiantou.png" alt="" className='jiantou' />
                                                        <select name="back"
                                                            style={{ width: '105px' }}
                                                            value={values.back}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={errors.back && touched.back ?
                                                                "input-error form-control dash-form-control" : "form-control dash-form-control"}>
                                                            <option value=''></option>

                                                            <option value='25'>25</option>
                                                            <option value='26'>26</option>

                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="" style={{ width: '190px' }}>{t("DATE")}
                                                </div>

                                                <div className="" style={{ width: '180px' }}>May</div>

                                                <div className="" style={{ width: '60px' }}>2023</div>
                                            </div>
                                            {errors.back && touched.back ? (
                                                <div className='err'>{errors.back}</div>
                                            ) : null}
                                        </div>
                                    </>
                                )
                            }


                            {
                                isSuccess&&tijiao ? (
                                    <div className='suceess_page'>
                                        <div style={{ height: '220px' }}></div>
                                        <div style={{
                                            width: '2000px',
                                            height: '2000px',
                                            position: 'fixed',
                                            top: '0px',
                                            backgroundColor: 'white',
                                            opacity: 0.4,
                                            left: 0
                                        }}></div>
                                        <div style={{}}>
                                            <img src="/img/tan-5-4.png" style={{
                                                width: '750px',
                                                padding: '0 100px',
                                                position: 'fixed',
                                                bottom: '50%',
                                                left: '50%',
                                                marginLeft: '-375px',
                                                marginBottom: '-130px',
                                            }} alt='' />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className="col-xs-2"></div>
                                            <div className="col-xs-8">
                                                {tijiao ? <button className="btn btn-default form-control btn-submit baoming"
                                                    style={{ margin: '80px 0 80px 0', color: '#fff' }}
                                                    type="submit">报名提交 SUBMIT
                                                </button> : <button className="btn btn-default form-control btn-submit baoming"
                                                    style={{ margin: '80px 0 80px 0', color: '#fff' }}
                                                    type="submit" disabled>报名提交 SUBMIT
                                                </button>}

                                            </div>
                                            <div className="col-xs-2"></div>
                                        </div>
                                    </div>
                                )

                            }

                        </Form>
                    )}
                </Formik>

            </div>

        </div>
    );
}

export default Dashboard;
