import React from 'react';
import { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useSearchParams } from 'react-router-dom';
import wx from 'weixin-js-sdk'
import { useShareMutation } from '../../store/shareApi';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Login from '../login';
import './home.css'
import { useInfoMutation, useUpdateMutation, useTimesMutation } from '../../store/pageApi2';


const Home = () => {
    useEffect(() => {
        document.title = '2023年小鹏汽车全球合作伙伴大会-行程安排登记'
    }, [])
    const [lang, setLang] = useState('zh')
    const [success, setsuccess] = useState(false)
    const [msg, setMsg] = useState({
        token: '',
        id: '',
        members: [],
        type: '',
    })

    const [info, { data: infoDData }] = useInfoMutation()
    const [times, { data: timesData, isSuccess }] = useTimesMutation()
    const [update] = useUpdateMutation()
    const { t, i18n } = useTranslation();
    const [tijiao, settijiao] = useState(true)
    const [count] = useSearchParams()
    const [login, setLogin] = useState(false)
    const infoHandle = (token, type) => {
        info({ token }).then(res => {
            console.log(res + '----')
            if (res.data.rs == 0) {
                setLogin(false)
                localStorage.removeItem('token')
                localStorage.removeItem('type')
            } else {
                setMsg({
                    token,
                    id: res.data.user_id,
                    members: res.data.members,
                    type
                })
            }
        });
    }
    const timesHandle = (type, scsj_type) => {

        times({ type, scsj_type }).then(res => {
            console.log(timesData)
            console.log(res)
        });
    }
    // const phoneTest = function (value) {
    //     var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;    //11位手机号码正则
    //     if (
    //         !reg_tel.test(value)
    //     ) {
    //         return false
    //     } else {
    //         return true
    //     }
    // }

    // const changeTest = (values, type) => {

    //     if (type === 2) {
    //         if (values.test_1.length === 0) {
    //             values.test_no = []
    //             console.log('-----2')
    //             console.log(values)
    //         }
    //         return
    //     }
    //     if (type === 1) {
    //         if (values.test_no.length === 0) {
    //             values.test_1 = []
    //             console.log('-----1')
    //             console.log(values)
    //         }
    //         return
    //     }
    // }



    const arriveItem = (values, handleChange, handleBlur, errors, touched) => {
        return (
            <>

                {
                    lang === 'zh' ? <>
                        {
                            (values.ddjtfs === '自驾') ? <>
                                <div className="form-group">
                                    <label>{t('p2-抵达会场时间')}</label>
                                    <div className=' chooseData' style={{ display: 'flex' }}>
                                        <div >2023 年 5 月 </div>
                                        <div className="jiantou_kuang" style={{ marginLeft: '10px' }}>
                                            <img src="/img/jiantou.png" alt="" className='jiantou' />
                                            <select name="ddhcsj_ri"
                                                style={{ width: '110px' }}
                                                value={values.ddhcsj_ri}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={errors.ddhcsj_ri && touched.ddhcsj_ri ?
                                                    "input-error form-control form-control-home" : "form-control form-control-home"}>
                                                <option value=''></option>
                                                <option value='24'>24</option>
                                                <option value='25'>25</option>
                                            </select>
                                        </div>
                                        <div style={{ width: '40px', textAlign: 'right' }}>{t("DATE")}
                                        </div>
                                        <div style={{ marginLeft: '23px' }}>时间：</div>
                                        <div className="jiantou_kuang" >
                                            <img src="/img/jiantou.png" alt="" className='jiantou' />
                                            <select name="ddhcsj_sj"
                                                style={{ width: '200px' }}
                                                value={values.ddhcsj_sj}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={errors.ddhcsj_sj && touched.ddhcsj_sj ?
                                                    "input-error form-control form-control-home" : "form-control form-control-home"}>
                                                {
                                                    values.ddhcsj_ri === '24' ? <>
                                                        <option value=''></option>
                                                        <option value='09:00-12:00'>09:00-12:00</option>
                                                        <option value='12:00-15:00'>12:00-15:00</option>
                                                        <option value='15:00-18:00'>15:00-18:00</option>
                                                        <option value='18:00-21:00'>18:00-21:00</option>
                                                        <option value='21:00-00:00'>21:00-00:00</option>
                                                    </> :
                                                        null
                                                }
                                                {
                                                    values.ddhcsj_ri === '25' ? <>
                                                        <option value=''></option>
                                                        <option value='09:00-12:00'>09:00-12:00</option>
                                                    </> :
                                                        null
                                                }

                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </> : null
                        }
                        {
                            (values.ddjtfs === '白云机场T1' || values.ddjtfs === '白云机场T2' || values.ddjtfs === '广州南站') ? <div className="form-group">
                                <label>{t("ARRIVAL")} </label>
                                <div className=' chooseData' style={{ display: 'flex' }}>
                                    <div >2023 年 5 月 </div>
                                    <div className="jiantou_kuang" style={{ marginLeft: '10px' }}>
                                        <img src="/img/jiantou.png" alt="" className='jiantou' />
                                        <select name="dzsj_ri"
                                            style={{ width: '110px' }}
                                            value={values.dzsj_ri}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={errors.dzsj_ri && touched.dzsj_ri ?
                                                "input-error form-control form-control-home" : "form-control form-control-home"}>
                                            <option value=''></option>
                                            <option value='24'>24</option>
                                            <option value='25'>25</option>
                                        </select>
                                    </div>
                                    <div style={{ width: '40px', textAlign: 'right' }}>{t("DATE")}
                                    </div>
                                    <div style={{ marginLeft: '23px' }}>时间： </div>
                                    <div className="jiantou_kuang" >
                                        <img src="/img/jiantou.png" alt="" className='jiantou' />
                                        <select name="dzsj_sj"
                                            style={{ width: '200px' }}
                                            value={values.dzsj_sj}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={errors.dzsj_sj && touched.dzsj_sj ?
                                                "input-error form-control form-control-home" : "form-control form-control-home"}>
                                            {
                                                values.dzsj_ri === '24' ? <>
                                                    <option value=''></option>
                                                    <option value='09:00-12:00'>09:00-12:00</option>
                                                    <option value='12:00-15:00'>12:00-15:00</option>
                                                    <option value='15:00-18:00'>15:00-18:00</option>
                                                    <option value='18:00-21:00'>18:00-21:00</option>
                                                    <option value='21:00-00:00'>21:00-00:00</option>
                                                </> :
                                                    null
                                            }
                                            {
                                                values.dzsj_ri === '25' ? <>
                                                    <option value=''></option>
                                                    <option value='09:00-12:00'>09:00-12:00</option>
                                                </> :
                                                    null
                                            }
                                        </select>
                                    </div>

                                </div>
                            </div> : null
                        }

                    </> : <>
                        {
                            (values.ddjtfs === '自驾') ? <>
                                <div className="form-group">
                                    <label>{t('p2-抵达会场时间')}</label>
                                    <div className=' chooseData' style={{ display: 'flex' }}>

                                        <div className="jiantou_kuang" >
                                            <img src="/img/jiantou.png" alt="" className='jiantou' />
                                            <select name="ddhcsj_sj"
                                                style={{ width: '200px' }}
                                                value={values.ddhcsj_sj}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={errors.ddhcsj_sj && touched.ddhcsj_sj ?
                                                    "input-error form-control form-control-home" : "form-control form-control-home"}>
                                                {
                                                    values.ddhcsj_ri === '24' ? <>
                                                        <option value=''></option>
                                                        <option value='09:00-12:00'>09:00-12:00</option>
                                                        <option value='12:00-15:00'>12:00-15:00</option>
                                                        <option value='15:00-18:00'>15:00-18:00</option>
                                                        <option value='18:00-21:00'>18:00-21:00</option>
                                                        <option value='21:00-00:00'>21:00-00:00</option>
                                                    </> :
                                                        null
                                                }
                                                {
                                                    values.ddhcsj_ri === '25' ? <>
                                                        <option value=''></option>
                                                        <option value='09:00-12:00'>09:00-12:00</option>
                                                    </> :
                                                        null
                                                }

                                            </select>
                                        </div>
                                        <div style={{ marginLeft: '10px', marginRight: '5px' }}>Time</div>
                                        <div className="jiantou_kuang" style={{ marginLeft: '10px' }}>
                                            <img src="/img/jiantou.png" alt="" className='jiantou' />
                                            <select name="ddhcsj_ri"
                                                style={{ width: '110px' }}
                                                value={values.ddhcsj_ri}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={errors.ddhcsj_ri && touched.ddhcsj_ri ?
                                                    "input-error form-control form-control-home" : "form-control form-control-home"}>
                                                <option value=''></option>
                                                <option value='24'>24</option>
                                                <option value='25'>25</option>
                                            </select>
                                        </div>

                                        <div >&nbsp;&nbsp;&nbsp;Date&nbsp;&nbsp;May&nbsp;&nbsp;2023 </div>


                                    </div>
                                </div>
                            </> : null
                        }
                        {
                            (values.ddjtfs === '白云机场T1' || values.ddjtfs === '白云机场T2' || values.ddjtfs === '广州南站') ? <div className="form-group">
                                <label>{t("p2-抵站时间")} </label>
                                <div className=' chooseData' style={{ display: 'flex' }}>

                                    <div className="jiantou_kuang" >
                                        <img src="/img/jiantou.png" alt="" className='jiantou' />
                                        <select name="dzsj_sj"
                                            style={{ width: '200px' }}
                                            value={values.dzsj_sj}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={errors.dzsj_sj && touched.dzsj_sj ?
                                                "input-error form-control form-control-home" : "form-control form-control-home"}>
                                            {
                                                values.dzsj_ri === '24' ? <>
                                                    <option value=''></option>
                                                    <option value='09:00-12:00'>09:00-12:00</option>
                                                    <option value='12:00-15:00'>12:00-15:00</option>
                                                    <option value='15:00-18:00'>15:00-18:00</option>
                                                    <option value='18:00-21:00'>18:00-21:00</option>
                                                    <option value='21:00-00:00'>21:00-00:00</option>
                                                </> :
                                                    null
                                            }
                                            {
                                                values.dzsj_ri === '25' ? <>
                                                    <option value=''></option>
                                                    <option value='09:00-12:00'>09:00-12:00</option>
                                                </> :
                                                    null
                                            }

                                        </select>
                                    </div>
                                    <div style={{ marginLeft: '10px', marginRight: '5px' }}>Time</div>
                                    <div className="jiantou_kuang" style={{ marginLeft: '10px' }}>
                                        <img src="/img/jiantou.png" alt="" className='jiantou' />
                                        <select name="dzsj_ri"
                                            style={{ width: '110px' }}
                                            value={values.dzsj_ri}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={errors.dzsj_ri && touched.dzsj_ri ?
                                                "input-error form-control form-control-home" : "form-control form-control-home"}>
                                            <option value=''></option>
                                            <option value='24'>24</option>
                                            <option value='25'>25</option>
                                        </select>
                                    </div>

                                    <div >&nbsp;&nbsp;&nbsp;Date&nbsp;&nbsp;May&nbsp;&nbsp;2023 </div>


                                </div>
                            </div> : null
                        }


                    </>
                }

                {errors.arrive && touched.arrive ? (
                    <div className='err'>{errors.arrive}</div>
                ) : null}
            </>
        )
    }

    // const arriveItem2 = (values, handleChange, handleBlur, errors, touched) => {
    //     return (
    //         <div className="form-group">
    //             <label style={{ paddingRight: '10px' }}>{t("arrive2")} </label>
    //             {
    //                 lang === 'zh' ? <>
    //                     <div style={{ marginTop: '15px' }}>
    //                         <label className="radio-inline" style={{ marginLeft: '60px' }}>
    //                             <input
    //                                 type="radio"
    //                                 name="arrive2"
    //                                 value='1'
    //                                 onChange={handleChange}
    //                                 onBlur={handleBlur}

    //                             />{t("arrive2-1")}
    //                         </label>
    //                         <label className="radio-inline label_eng_text_test">
    //                             <input
    //                                 type="radio"
    //                                 name="arrive2"
    //                                 value='2'
    //                                 onChange={handleChange}
    //                                 onBlur={handleBlur}
    //                             />{t("arrive2-2")}
    //                         </label>

    //                     </div>
    //                     <div style={{ marginTop: '15px' }}>
    //                         <label className="radio-inline" style={{ marginLeft: '61px' }}>
    //                             <input
    //                                 type="radio"
    //                                 name="arrive2"
    //                                 value='3'
    //                                 onChange={handleChange}
    //                                 onBlur={handleBlur}
    //                                 className='label_eng_text_test'
    //                             />{t("arrive2-3")}
    //                         </label>
    //                         <label className="radio-inline label_eng_text_test">
    //                             <input
    //                                 type="radio"
    //                                 name="arrive2"
    //                                 value='2'
    //                                 onChange={handleChange}
    //                                 onBlur={handleBlur}

    //                             />{t("arrive2-4")}
    //                         </label>
    //                     </div>
    //                 </> : <>

    //                     <div style={{ marginTop: '15px' }}>
    //                         <label className="radio-inline2 radio-inline">
    //                             <input
    //                                 type="radio"
    //                                 name="arrive2"
    //                                 value='1'
    //                                 onChange={handleChange}
    //                                 onBlur={handleBlur}

    //                             />{t("arrive2-1")}
    //                         </label>
    //                     </div>
    //                     <div style={{ marginTop: '15px' }}>
    //                         <label className="radio-inline2 radio-inline">
    //                             <input
    //                                 type="radio"
    //                                 name="arrive2"
    //                                 value='2'
    //                                 onChange={handleChange}
    //                                 onBlur={handleBlur}
    //                             />{t("arrive2-2")}
    //                         </label>
    //                     </div>

    //                     <div style={{ marginTop: '15px' }}>
    //                         <label className="radio-inline2 radio-inline" >
    //                             <input
    //                                 type="radio"
    //                                 name="arrive2"
    //                                 value='3'
    //                                 onChange={handleChange}
    //                                 onBlur={handleBlur}
    //                                 className=''
    //                             />{t("arrive2-3")}
    //                         </label>
    //                     </div>
    //                     <div style={{ marginTop: '15px' }}>

    //                         <label className="radio-inline2 radio-inline">
    //                             <input
    //                                 type="radio"
    //                                 name="arrive2"
    //                                 value='2'
    //                                 onChange={handleChange}
    //                                 onBlur={handleBlur}

    //                             />{t("arrive2-4")}
    //                         </label>
    //                     </div>
    //                 </>
    //             }

    //             {errors.arrive2 && touched.arrive2 ? (
    //                 <div className='err'>{errors.arrive2}</div>
    //             ) : null}
    //         </div>
    //     )
    // }

    // const mealsItem = (values, handleChange, handleBlur, errors, touched) => {
    //     return (
    //         <div className="form-group">
    //             <label style={{ paddingRight: '10px' }}>{t("meals")} </label>
    //             <div style={{ marginTop: '15px' }}>
    //                 <label className="checkbox-inline checkbox-inline2" >
    //                     <input
    //                         type="checkbox"
    //                         name="meals"
    //                         value='1'
    //                         onChange={handleChange}
    //                         onBlur={handleBlur}
    //                     />{t("wucan")}
    //                 </label>
    //                 <label className="checkbox-inline label_eng_text_test">
    //                     <input
    //                         type="checkbox"
    //                         name="meals"
    //                         value='2'
    //                         onChange={handleChange}
    //                         onBlur={handleBlur}
    //                     />{t("wancan")}
    //                 </label>
    //             </div>
    //             {errors.meals && touched.meals ? (
    //                 <div className='err'>{errors.meals}</div>
    //             ) : null}
    //         </div>
    //     )
    // }

    const returnItem = (values, handleChange, handleBlur, errors, touched) => {
        return (
            <div className="form-group">
                <label>{t('p2-返程时间')}</label>
                <div className=' chooseData' style={{ display: 'flex' }}>
                    {
                        lang === 'zh' ? <>
                            <div >2023 年 5 月 </div>
                            <div className="jiantou_kuang" style={{ marginLeft: '10px' }}>
                                <img src="/img/jiantou.png" alt="" className='jiantou' />
                                <select name="fcsj_ri"
                                    style={{ width: '110px' }}
                                    value={values.fcsj_ri}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.fcsj_ri && touched.fcsj_ri ?
                                        "input-error form-control form-control-home" : "form-control form-control-home"}>
                                    <option value=''></option>
                                    <option value='25'>25</option>
                                    <option value='26'>26</option>
                                </select>
                            </div>
                            <div style={{ width: '40px', textAlign: 'right' }}>{t("DATE")}
                            </div>
                            <div style={{ marginLeft: '23px' }}>时间：</div>
                            <div className="jiantou_kuang" >
                                <img src="/img/jiantou.png" alt="" className='jiantou' />
                                <select name="fcsj_sj"
                                    style={{ width: '200px' }}
                                    value={values.fcsj_sj}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.fcsj_sj && touched.fcsj_sj ?
                                        "input-error form-control form-control-home" : "form-control form-control-home"}>
                                    {
                                        values.fcsj_ri === '25' ? <>
                                            <option value=''></option>
                                            <option value='20:30-00:00'>20:30-00:00</option>
                                        </> :
                                            null
                                    }
                                    {
                                        values.fcsj_ri === '26' ? <>
                                            <option value=''></option>
                                            <option value='09:00-12:00'>09:00-12:00</option>
                                            <option value='12:00-15:00'>12:00-15:00</option>
                                            <option value='15:00-18:00'>15:00-18:00</option>
                                            <option value='18:00-21:00'>18:00-21:00</option>
                                            <option value='21:00-00:00'>21:00-00:00</option>
                                        </> :
                                            null
                                    }
                                </select>
                            </div>
                        </> : <>
                            <div className="jiantou_kuang" >
                                <img src="/img/jiantou.png" alt="" className='jiantou' />
                                <select name="fcsj_sj"
                                    style={{ width: '200px' }}
                                    value={values.fcsj_sj}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.fcsj_sj && touched.fcsj_sj ?
                                        "input-error form-control form-control-home" : "form-control form-control-home"}>
                                    {
                                        values.fcsj_ri === '26' ? <>
                                            <option value=''></option>
                                            <option value='09:00-12:00'>09:00-12:00</option>
                                            <option value='12:00-15:00'>12:00-15:00</option>
                                            <option value='15:00-18:00'>15:00-18:00</option>
                                            <option value='18:00-21:00'>18:00-21:00</option>
                                            <option value='21:00-00:00'>21:00-00:00</option>
                                        </> :
                                            null
                                    }
                                    {
                                        values.fcsj_ri === '25' ? <>
                                            <option value=''></option>
                                            <option value='20:30-00:00'>20:30-00:00</option>
                                        </> :
                                            null
                                    }

                                </select>
                            </div>
                            <div style={{ marginLeft: '10px', marginRight: '5px' }}>Time</div>
                            <div className="jiantou_kuang" style={{ marginLeft: '10px' }}>
                                <img src="/img/jiantou.png" alt="" className='jiantou' />
                                <select name="fcsj_ri"
                                    style={{ width: '110px' }}
                                    value={values.fcsj_ri}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.fcsj_ri && touched.fcsj_ri ?
                                        "input-error form-control form-control-home" : "form-control form-control-home"}>
                                    <option value=''></option>
                                    <option value='25'>25</option>
                                    <option value='26'>26</option>
                                </select>
                            </div>

                            <div >&nbsp;&nbsp;&nbsp;Date&nbsp;&nbsp;May&nbsp;&nbsp;2023</div>
                        </>
                    }


                </div>
            </div>
        )
    }

    // const tryItem = (values, handleChange, handleBlur, errors, touched) => {
    //     return (
    //         <div className="form-group">
    //             <label style={{ paddingRight: '10px' }}>{t("TEST")} </label>

    //             {
    //                 lang === 'zh' ? <>
    //                     <div style={{ marginTop: '15px' }}>
    //                         <label className="checkbox-inline" style={{ marginLeft: '60px' }}>
    //                             <input
    //                                 type="checkbox"
    //                                 name="test_1"
    //                                 value='1'
    //                                 onChange={(e) => {
    //                                     handleChange(e);
    //                                     changeTest(values, 2);
    //                                 }}
    //                                 onBlur={handleBlur}
    //                             // checked={values.test_1.includes('1')}

    //                             />{t("test1")}
    //                         </label>
    //                         <label className="checkbox-inline label_eng_text_test">
    //                             <input
    //                                 type="checkbox"
    //                                 name="test_1"
    //                                 value='2'
    //                                 onChange={(e) => {
    //                                     handleChange(e);
    //                                     changeTest(values, 2);
    //                                 }}
    //                                 onBlur={handleBlur}
    //                             // checked={values.test_1.includes('2')}
    //                             />{t("test2")}
    //                         </label>

    //                     </div>
    //                     <div style={{ marginTop: '15px' }}>
    //                         <label className="checkbox-inline" style={{ marginLeft: '61px' }}>
    //                             <input
    //                                 type="checkbox"
    //                                 name="test_1"
    //                                 value='3'
    //                                 onChange={(e) => {
    //                                     handleChange(e);
    //                                     changeTest(values, 2);
    //                                 }}
    //                                 onBlur={handleBlur}
    //                                 // checked={values.test_1.includes('3')}
    //                                 className='label_eng_text_test'
    //                             />{t("test3")}
    //                         </label>
    //                         <label className="checkbox-inline label_eng_text_test">
    //                             <input
    //                                 type="checkbox"
    //                                 name="test_no"
    //                                 value='1'
    //                                 onChange={(e) => {
    //                                     handleChange(e);
    //                                     changeTest(values, 1);
    //                                 }}
    //                                 onBlur={handleBlur}

    //                             // checked={values.test_no.length === 1}

    //                             />{t("ATTEND")}
    //                         </label>
    //                     </div>
    //                 </> : <>
    //                     <div style={{ marginTop: '15px' }}>
    //                         <label className="checkbox-inline checkbox-inline2" >
    //                             <input
    //                                 type="checkbox"
    //                                 name="test_1"
    //                                 value='1'
    //                                 onChange={(e) => {
    //                                     handleChange(e);
    //                                     changeTest(values, 2);
    //                                 }}
    //                                 onBlur={handleBlur}
    //                             // checked={values.test_1.includes('1')}

    //                             />{t("test1")}
    //                         </label>
    //                     </div>
    //                     <div style={{ marginTop: '15px' }}>
    //                         <label className="checkbox-inline checkbox-inline2 ">
    //                             <input
    //                                 type="checkbox"
    //                                 name="test_1"
    //                                 value='2'
    //                                 onChange={(e) => {
    //                                     handleChange(e);
    //                                     changeTest(values, 2);
    //                                 }}
    //                                 onBlur={handleBlur}
    //                             // checked={values.test_1.includes('2')}
    //                             />{t("test2")}
    //                         </label>
    //                     </div>
    //                     <div style={{ marginTop: '15px' }}>
    //                         <label className="checkbox-inline checkbox-inline2" >
    //                             <input
    //                                 type="checkbox"
    //                                 name="test_1"
    //                                 value='3'
    //                                 onChange={(e) => {
    //                                     handleChange(e);
    //                                     changeTest(values, 2);
    //                                 }}
    //                                 onBlur={handleBlur}
    //                                 // checked={values.test_1.includes('3')}
    //                                 className=''
    //                             />{t("test3")}
    //                         </label>

    //                     </div>
    //                     <div style={{ marginTop: '15px' }}>

    //                         <label className="checkbox-inline checkbox-inline2">
    //                             <input
    //                                 type="checkbox"
    //                                 name="test_no"
    //                                 value='1'
    //                                 onChange={(e) => {
    //                                     handleChange(e);
    //                                     changeTest(values, 1);
    //                                 }}
    //                                 onBlur={handleBlur}

    //                             // checked={values.test_no.length === 1}

    //                             />{t("ATTEND")}
    //                         </label>
    //                     </div>
    //                 </>
    //             }

    //             {errors.test_no && touched.test_no ? (
    //                 <div className='err'>{errors.test_no}</div>
    //             ) : null}
    //         </div>
    //     )
    // }


    const idItem = (values, handleChange, handleBlur, errors, touched) => {
        return (
            <>

                <div className="form-group" style={{ marginTop: '30px' }}>
                    <div>{t('p2-感谢您参与小鹏汽车试乘试驾体验')}</div>
                    <div className='xiao2'>{t('p2-请留意')}</div>
                    <div className='xiao2'>{t('p2-同时')}</div>
                </div>

                <div className="form-group" style={{ display: 'flex' }}>
                    <div >{t("p2-证件类型")} </div>
                    <div >
                        <label className="radio-inline baseline-input" style={lang === 'zh' ? { marginLeft: '45px' } : { marginLeft: '0px' }}>
                            <input
                                type="radio"
                                name="sfz_type"
                                value='1'
                                checked={values.sfz_type === '1'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            /> {t("p2-身份证")}
                        </label>
                        <label className="radio-inline baseline-input" style={lang === 'zh' ? { marginLeft: '80px' } : { marginLeft: '30px' }}>
                            <input
                                type="radio"
                                name="sfz_type"
                                value='2'
                                checked={values.sfz_type === '2'}
                                onChange={handleChange}
                                onBlur={handleBlur}

                            /> {t("p2-护照")}
                        </label>
                    </div>


                </div>
                {errors.sfz_type && touched.sfz_type ? (
                    <div className='err'>{errors.sfz_type}</div>
                ) : null}
                {
                    values.sfz_type === '1' ?
                        <div className="form-group">
                            <div className='title1'>{t("p2-身份证号码")}</div>
                            <input type="input" name="idcard"
                                value={values.idcard}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.idcard && touched.idcard ?
                                    "input-error form-control form-control-home" : "form-control form-control-home"}
                            />
                            {errors.idcard && touched.idcard ? (
                                <div className='err'>{errors.idcard}</div>
                            ) : null}
                        </div>
                        : null
                }
                {
                    values.sfz_type === '2' ?
                        <div className="form-group">
                            <div className='title1'>{t("p2-护照号码")}</div>
                            <input type="input" name="passport"
                                value={values.passport}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.passport && touched.passport ?
                                    "input-error form-control form-control-home" : "form-control form-control-home"}
                            />
                            {errors.passport && touched.passport ? (
                                <div className='err'>{errors.passport}</div>
                            ) : null}
                        </div>
                        : null
                }
            </>

        )
    }

    const submitItem = (values, handleChange, handleBlur, errors, touched) => {
        return (
            <>
                {
                    success ? (
                        <div className='suceess_page'>
                            <div style={{ height: '220px' }}></div>
                            <div style={{
                                width: '2000px',
                                height: '2000px',
                                position: 'fixed',
                                top: '0px',
                                backgroundColor: 'white',
                                opacity: 0.4,
                                left: 0
                            }}></div>
                            <div style={{}}>
                                <img src="/img/p2-tan.png" style={{
                                    width: '750px',
                                    padding: '0 100px',
                                    position: 'fixed',
                                    bottom: '50%',
                                    left: '50%',
                                    marginLeft: '-375px',
                                    marginBottom: '-130px',
                                }} alt='' />
                            </div>
                        </div>
                    ) : (
                        <div className="form-group">
                            <div className='row'>
                                <div className="col-xs-2"></div>
                                <div className="col-xs-8">
                                    {tijiao ? <button className="btn btn-default form-control form-control-home btn-submit baoming"
                                        style={{ margin: '80px 0 80px 0', color: '#fff' }}
                                        type="submit">报名提交 SUBMIT
                                    </button> : <button className="btn btn-default form-control form-control-home btn-submit baoming"
                                        style={{ margin: '80px 0 80px 0', color: '#fff' }}
                                        type="submit" disabled>报名提交 SUBMIT
                                    </button>}

                                </div>
                                <div className="col-xs-2"></div>
                            </div>
                        </div>
                    )

                }
            </>
        )
    }
    const SignupSchema = Yup.object().shape({


        // arrive: Yup.string()
        //     .required(t("p2-alert-arrive")),
        // arrive2: Yup.string()
        //     .required(t("p2-alert-arrive2")),
        // train: Yup.string()
        //     .required(t("p2-alert-train")),
        // meals: Yup.array()
        //     .min(1, t("p2-alert-meals")),
        // return: Yup.string()
        //     .required(t("p2-alert-return")),
        // test_no: Yup.array().when("test_1", {
        //     is: (value) => value.length === 0,
        //     then: () => Yup.array().min(1, t("p2-alert-test_no")),
        // }),
        // type: Yup.string().when("test_1", {
        //     is: (value) => value.length >= 1,
        //     then: () => Yup.string()
        //         .required(t("p2-alert-type")),
        // }),

        scsj: Yup.string().required(t("p2-alert-请选择是否参与试乘试驾环节")),
        scsj_type: Yup.string().when("scsj", {
            is: (value) => value === '1',
            then: () => Yup.string().required(t("p2-alert-请选择试乘试驾类型")),
        }),
        ty_type: Yup.string().when("scsj", {
            is: (value) => value === '1',
            then: () => Yup.string().required(t("p2-alert-请选择体验类型")),
        }),
        tysj: Yup.string().when("scsj", {
            is: (value) => value === '1',
            then: () => Yup.string().required(t("p2-alert-请选择体验时间预约")),
        }),
        sfz_type: Yup.string().when("scsj", {
            is: (value) => value === '1',
            then: () => Yup.string().required(t("p2-alert-请选择证件类型")),
        }),
        idcard: Yup.string().when("sfz_type", {
            is: (value) => value === '1',
            then: () => Yup.string().required(t("p2-alert-idcard")).min(18, t("p2-alert-idcard2")),
        }),
        passport: Yup.string().when("sfz_type", {
            is: (value) => value === '2',
            then: () => Yup.string().required(t("p2-alert-passport"))
        }),

        ddjtfs: Yup.string().required(t("p2-alert-请选择抵达交通方式及站点")),


        dzsj_ri: Yup.string().when("ddjtfs", {
            is: (value) => value === '白云机场T1' || value === '白云机场T2' || value === '广州南站',
            then: () => Yup.string().required("请选择抵站日"),
        }),
        dzsj_sj: Yup.string().when("ddjtfs", {
            is: (value) => value === '白云机场T1' || value === '白云机场T2' || value === '广州南站',
            then: () => Yup.string().required("请选择抵站时间"),
        }),

        ddhcsj_ri: Yup.string().when("ddjtfs", {
            is: (value) => value === '自驾',
            then: () => Yup.string().required("请选择抵达会场日"),
        }),
        ddhcsj_sj: Yup.string().when("ddjtfs", {
            is: (value) => value === '自驾',
            then: () => Yup.string().required("请选择抵达会场时间"),
        }),

        // cph: Yup.string().when("ddjtfs", {
        //     is: (value) => value === '自驾',
        //     then: () => Yup.string().required(t("p2-alert-请填写车牌号")),
        // }),
        fcsj_ri: Yup.string().required(t("p2-alert-请选择返程日")),
        fcsj_sj: Yup.string().required(t("p2-alert-请选择返程时间")),
        fcjt: Yup.string().required(t("p2-alert-请选择抵达交通方式及站点")),


        rzxq: Yup.string().required(t("p2-alert-请选择入住需求")),
        ruzhu_ri: Yup.string().when("rzxq", {
            is: (value) => value === '2',
            then: () => Yup.string().required(t("p2-alert-请选择入住时间")),
        }),
        tf_ri: Yup.string().when("rzxq", {
            is: (value) => value === '2',
            then: () => Yup.string().required(t("p2-alert-请选择退房时间")),
        }),


    });

    const homeItem = () => {
        return (

            <div style={{
                fontWeight: '400', maxWidth: '750px', margin: '0 auto', position: 'relative', fontSize: '24px', padding: 0
            }} className='container'>
                <div style={{

                    backgroundImage: "linear-gradient(rgb(227, 233, 242), rgb(230 236 244))",
                    backgroundSize: '100% 100%',
                    backgroundAttachment: 'fixed',
                    position: 'absolute',
                    zIndex: '-1',
                    width: "100%",
                    height: "100%"
                }}></div>
                <img src="/img/p2-bg-1.png" alt="" style={{ width: '100%' }} />

                <div >



                    <Formik
                        initialValues={{
                            rzxq: '',
                            ch_name: '',
                            scsj: '',
                            scsj_type: '',
                            tysj: '',
                            ty_type: '',
                            idcard: '',
                            passport: '',
                            sfz_type: '',
                            ddjtfs: '',
                            dzsj_ri: '',
                            dzsj_sj: '',
                            ddhcsj_ri: '',
                            ddhcsj_sj: '',
                            hbf: '',
                            cch: '',
                            cph: '',
                            fcsj_ri: '',
                            fcsj_sj: '',
                            fcjt: '',
                            fc_hbh: '',
                            fc_cch: '',
                            ruzhu_ri: '',
                            tf_ri: '',

                        }}
                        validationSchema={SignupSchema}
                        onSubmit={values => {
                            console.log(values + '-----')
                            if (values.ch_name === '' && msg.type === '2') {
                                return
                            }
                            settijiao(false)


                            update({

                                token: msg.token,
                                user_id: msg.members[values.ch_name] ? msg.members[values.ch_name].id : msg.id,
                                scsj: values.scsj,
                                scsj_type: values.scsj_type,
                                tysj: values.tysj,
                                ty_type: values.ty_type,
                                id_card: values.idcard,
                                passport: values.passport,
                                sfz_type: values.sfz_type,
                                ddjtfs: values.ddjtfs,
                                dzsj_ri: values.dzsj_ri,
                                dzsj_sj: values.dzsj_sj,
                                ddhcsj_ri: values.ddhcsj_ri,
                                ddhcsj_sj: values.ddhcsj_sj,
                                hbf: values.hbf,
                                cch: values.cch,
                                cph: values.cph,
                                fcsj_ri: values.fcsj_ri,
                                fcsj_sj: values.fcsj_sj,
                                fcjt: values.fcjt,
                                fc_hbh: values.fc_hbh,
                                fc_cch: values.fc_cch,
                                ruzhu_ri: values.ruzhu_ri,
                                tf_ri: values.tf_ri,
                                rzxq: values.rzxq,

                            }).then(res => {
                                console.log(res)

                                if (res.data.rs) {
                                    setsuccess(true)
                                    settijiao(true)
                                }
                                if (!res.data.rs) {
                                    settijiao(true)
                                    alert(res.data.msg)
                                }
                            });
                        }}
                    >
                        {({ values, handleChange, handleBlur, errors, touched }) => (
                            <Form>
                                <div style={{ padding: '0 80px', backgroundImage: "linear-gradient(to right, rgb(223 233 242), rgb(223 232 241), rgb(222 232 241))" }}>
                                    <div style={{ marginBottom: '30px' }}>
                                        <label className="radio-inline-lang radio-inline" >
                                            <input
                                                type="radio"
                                                name="lang"
                                                value='zh'
                                                onChange={(e) => {
                                                    i18n.changeLanguage(e.target.value);
                                                    setLang('zh')
                                                }}
                                                checked={lang === 'zh'}
                                            /> 简体中文
                                        </label>
                                        <label className="radio-inline" >
                                            <input
                                                type="radio"
                                                name="lang"
                                                value='en'
                                                onChange={(e) => {
                                                    i18n.changeLanguage(e.target.value);
                                                    setLang('en')
                                                }}
                                                checked={lang !== 'zh'}
                                            /> English
                                        </label>
                                    </div>

                                    {
                                        msg.type === '2' ?
                                            <>
                                                <div className="form-group">
                                                    <div className='title1'>{t('p2-请选择参会嘉宾')}</div>

                                                    <div className="jiantou_kuang" >
                                                        <img src="/img/jiantou.png" alt="" className='jiantou2' />
                                                        <select name="ch_name"
                                                            value={values.ch_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={errors.ch_name && touched.ch_name ?
                                                                "input-error form-control form-control-home" : "form-control form-control-home"}>
                                                            <option value=''></option>
                                                            {
                                                                msg.members.length > 0 ? msg.members.map((e, index) => {
                                                                    return <option value={index} key={e.id}>{e.name}</option>
                                                                }) : null
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className='xiao1'>{t('p2-分别')}</div>
                                                    {msg.members.length > 0 && touched.ch_name && values.ch_name === '' ? (
                                                        <div className='err'>{t('p2-alert-请选择参会嘉宾')}</div>
                                                    ) : null}
                                                </div>
                                                <div>
                                                    <label style={{ marginBottom: '20px' }}>
                                                        {t("p2-公司全称")}{infoDData ? infoDData.company_name : null}
                                                    </label>
                                                    <br />
                                                    <label style={{ marginBottom: '30px' }}>

                                                        {t("p2-参会嘉宾姓名")}{

                                                            msg.members[values.ch_name] ? msg.members[values.ch_name].name : ""
                                                        }
                                                    </label>
                                                </div>
                                            </>

                                            :
                                            <div>
                                                <label style={{ marginBottom: '20px' }}>
                                                    {t("Company")}{infoDData ? infoDData.company_name : null}
                                                </label>
                                                <br />
                                                <label style={{ marginBottom: '30px' }}>
                                                    {t("Participant")}{infoDData ? infoDData.name : null}
                                                </label>
                                            </div>
                                    }


                                </div>
                                <div style={{ padding: '0 80px', backgroundImage: "linear-gradient(rgb(222 231 241), rgb(231 237 244))", paddingBottom: '20px' }}>
                                    <div style={{ padding: '20px 0' }}>
                                        <span className='title2'>{t('p2-试乘试驾')}</span>
                                    </div>

                                    {
                                        lang === 'zh' ? <div className="form-group" style={{ display: 'flex' }}>
                                            <div >{t('p2-您是否参与试乘试驾环节')}</div>
                                            <div >
                                                <label className="radio-inline baseline-input" >
                                                    <input
                                                        type="radio"
                                                        name="scsj"
                                                        value='1'
                                                        // checked={values.type === '1'}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            values.ty_type = ''
                                                            values.tysj = ''
                                                        }}
                                                        onBlur={handleBlur}
                                                    /> {t('p2-参与')}
                                                </label>
                                                <label className="radio-inline baseline-input">
                                                    <input
                                                        type="radio"
                                                        name="scsj"
                                                        value='2'
                                                        // checked={values.type == '2'}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            values.ty_type = ''
                                                            values.tysj = ''
                                                        }}
                                                        onBlur={handleBlur}
                                                    /> {t('p2-不参与')}
                                                </label>
                                            </div>

                                        </div> : <div className="form-group" >
                                            <div >{t('p2-您是否参与试乘试驾环节')}</div>
                                            <div style={{ marginTop: '15px' }}>
                                                <label className="radio-inline baseline-input-en1" style={{ paddingLeft: '40px !important' }}>
                                                    <input
                                                        type="radio"
                                                        name="scsj"
                                                        value='1'
                                                        // checked={values.type === '1'}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            values.ty_type = ''
                                                            values.tysj = ''
                                                        }}
                                                        onBlur={handleBlur}
                                                    /> {t('p2-参与')}
                                                </label>
                                                <label className="radio-inline baseline-input-en2">
                                                    <input
                                                        type="radio"
                                                        name="scsj"
                                                        value='2'
                                                        // checked={values.type == '2'}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            values.ty_type = ''
                                                            values.tysj = ''
                                                        }}
                                                        onBlur={handleBlur}
                                                    /> {t('p2-不参与')}
                                                </label>
                                            </div>

                                        </div>
                                    }

                                    {errors.scsj && touched.scsj ? (
                                        <div className='err'>{errors.scsj}</div>
                                    ) : null}
                                    {
                                        values.scsj === "1" ? <>

                                            <div className="form-group">
                                                <div className='title1'>{t('p2-请选择试驾类型')}</div>
                                                <div className="jiantou_kuang" >
                                                    <img src="/img/jiantou.png" alt="" className='jiantou2' />
                                                    <select name="scsj_type"
                                                        value={values.scsj_type}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            values.ty_type = ''
                                                        }}
                                                        onBlur={handleBlur}
                                                        className={errors.scsj_type && touched.scsj_type ?
                                                            "input-error form-control form-control-home" : "form-control form-control-home"}>
                                                        <option value=''></option>
                                                        <option value='1'>{t('p2-XNGP深度体验')}</option>
                                                        <option value='2'>{t('p2-风景线试乘试驾')}</option>
                                                    </select>
                                                </div>
                                                {errors.scsj_type && touched.scsj_type ? (
                                                    <div className='err'>{errors.scsj_type}</div>
                                                ) : null}
                                            </div>
                                            {
                                                
                                            }
                                            {
                                                values.scsj_type === '1' ? <div style={{ position: 'relative' }}>
                                                    <img src={lang==='zh'?"/img/p2-xing2-5-11.png":"/img/en-xngp-5-12-1.png"} alt="" style={lang==='zh'?{ width: '445px', marginBottom: '20px',marginTop:'10px' }:{ width: '95%', marginBottom: '20px',marginTop:'10px' }} />
                                                    <a href="https://mp.weixin.qq.com/s/QvqjPvKXKIkxjP4Dncz6yA"><div className='lianjie'>haha</div></a>
                                                </div> : null
                                            }
                                            {
                                                values.scsj_type === '2' ? <>
                                                <img src={lang==='zh'?"/img/p2-xing1-5-11.png":"/img/en-xngp-5-12-2.png"} alt="" style={{ width: '90%', marginBottom: '20px',marginTop:'10px' }} />
                                                </> : null
                                            }

                                            {
                                                values.scsj_type !== "" ? <>
                                                    <div className="form-group" style={{ display: 'flex' }}>
                                                        <div >{t('p2-体验选择')}</div>
                                                        <div >
                                                            <label className="radio-inline baseline-input" style={lang === 'zh' ? { marginLeft: '45px' } : { marginLeft: '0px' }}>
                                                                <input
                                                                    type="radio"
                                                                    name="ty_type"
                                                                    value='1'
                                                                    checked={values.ty_type === '1'}
                                                                    onChange={(e) => {
                                                                        handleChange(e)
                                                                        console.log(values.ty_type)
                                                                        timesHandle(1, values.scsj_type)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                /> {t('p2-试驾')}
                                                            </label>
                                                            <label className="radio-inline baseline-input" style={lang === 'zh' ? { marginLeft: '100px' } : { marginLeft: '30px' }}>
                                                                <input
                                                                    type="radio"
                                                                    name="ty_type"
                                                                    value='2'
                                                                    checked={values.ty_type === '2'}
                                                                    onChange={(e) => {
                                                                        handleChange(e)

                                                                        timesHandle(2, values.scsj_type)
                                                                    }}
                                                                    onBlur={handleBlur}

                                                                /> {t('p2-试乘')}
                                                            </label>
                                                        </div>

                                                    </div>
                                                    {errors.ty_type && touched.ty_type ? (
                                                        <div className='err'>{errors.ty_type}</div>
                                                    ) : null}
                                                    {
                                                        values.ty_type !== "" ? <>
                                                            <div className="form-group">
                                                                <div className='title1'>{t('p2-体验时间预约')}</div>

                                                                <div className="jiantou_kuang" >
                                                                    <img src="/img/jiantou.png" alt="" className='jiantou2' />
                                                                    <select name="tysj"
                                                                        value={values.tysj}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.tysj && touched.tysj ?
                                                                            "input-error form-control form-control-home" : "form-control form-control-home"}>
                                                                        <option value=''></option>
                                                                        {
                                                                            timesData ? timesData.map((e, index) => {
                                                                                return <option value={e.id} key={e.id}> {e.range}</option>
                                                                            }) : null
                                                                        }
                                                                        {
                                                                            timesData && timesData.length === 0 ? <option value=''>{t('p2-该预约时段已满')}</option> : null
                                                                        }

                                                                    </select>
                                                                </div>

                                                                {errors.tysj && touched.tysj ? (
                                                                    <div className='err'>{errors.tysj}</div>
                                                                ) : null}
                                                            </div>
                                                        </> : null
                                                    }

                                                </> : null
                                            }
                                            {idItem(values, handleChange, handleBlur, errors, touched)}

                                        </> : null
                                    }


                                    {/* {tryItem(values, handleChange, handleBlur, errors, touched)} */}
                                    <div className='title2-kuang'>
                                        <span className='title2'>{t('p2-行程安排')}</span>
                                    </div>

                                    <div className="form-group">
                                        <div className='title1'>{t('p2-抵达交通方式及站点')}</div>
                                        <div className="jiantou_kuang" >
                                            <img src="/img/jiantou.png" alt="" className='jiantou2' />
                                            <select name="ddjtfs"
                                                value={values.ddjtfs}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={errors.ddjtfs && touched.ddjtfs ?
                                                    "input-error form-control form-control-home" : "form-control form-control-home"}>
                                                <option value=''></option>
                                                <option value='白云机场T1'>{t('p2-白云机场T1')}</option>
                                                <option value='白云机场T2'>{t('p2-白云机场T2')}</option>
                                                <option value='广州南站'>{t('p2-广州南站')}</option>
                                                <option value='自驾'>{t('p2-自驾')}</option>
                                            </select>
                                        </div>
                                        {errors.ddjtfs && touched.ddjtfs ? (
                                            <div className='err'>{errors.ddjtfs}</div>
                                        ) : null}
                                    </div>
                                    {arriveItem(values, handleChange, handleBlur, errors, touched)}

                                    {
                                        (values.ddjtfs === '白云机场T1' || values.ddjtfs === '白云机场T2') ? <>
                                            <div className="form-group">
                                                <div className='title1'>{t('p2-【选填】 请填写航班号：')}</div>
                                                <input type="input" name="hbf"
                                                    value={values.hbf}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className='form-control form-control-home'
                                                />
                                                <div className='xiao1'>{t('p2-届时')}</div>
                                            </div>
                                        </> : null
                                    }
                                    {
                                        (values.ddjtfs === '广州南站') ? <>
                                            <div className="form-group">
                                                <div className='title1'>{t('p2-【选填】请填写车次号：')}</div>
                                                <input type="input" name="cch"
                                                    value={values.cch}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className='form-control form-control-home'
                                                />
                                                <div className='xiao1'>{t('p2-届时')}</div>
                                            </div>
                                        </> : null
                                    }


                                    {
                                        values.ddjtfs === '自驾' ? <>
                                            <div className="form-group">
                                                <div className='title1'>{t("p2-请填写车牌号")}</div>
                                                <input type="input" name="cph"
                                                    value={values.cph}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.cph && touched.cph ?
                                                        "input-error form-control form-control-home" : "form-control form-control-home"}
                                                />
                                                <div className='xiao1'>{t('p2-停车位')}</div>
                                                {errors.cph && touched.cph ? (
                                                    <div className='err'>{errors.cph}</div>
                                                ) : null}
                                            </div>
                                        </> : null
                                    }


                                    {returnItem(values, handleChange, handleBlur, errors, touched)}
                                    <div className="form-group">
                                        <div className='title1'>{t('p2-返程交通方式及站点')}</div>
                                        <div className="jiantou_kuang" >
                                            <img src="/img/jiantou.png" alt="" className='jiantou2' />
                                            <select name="fcjt"
                                                value={values.fcjt}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={errors.fcjt && touched.fcjt ?
                                                    "input-error form-control form-control-home" : "form-control form-control-home"}>
                                                <option value=''></option>
                                                <option value='白云机场T1'>{t('p2-白云机场T1')}</option>
                                                <option value='白云机场T2'>{t('p2-白云机场T2')}</option>
                                                <option value='广州南站'>{t('p2-广州南站')}</option>
                                                <option value='自驾'>{t('p2-自驾')}</option>
                                            </select>
                                        </div>
                                        {errors.fcjt && touched.fcjt ? (
                                            <div className='err'>{errors.fcjt}</div>
                                        ) : null}
                                    </div>
                                    {
                                        (values.fcjt === '白云机场T1' || values.fcjt === '白云机场T2') ? <>
                                            <div className="form-group">
                                                <div className='title1'>{t('p2-【选填】 请填写航班号：')}</div>
                                                <input type="input" name="fc_hbh"
                                                    value={values.fc_hbh}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className='form-control form-control-home'
                                                />
                                                <div className='xiao1'>{t('p2-届时')}</div>
                                            </div>
                                        </> : null
                                    }
                                    {
                                        (values.fcjt === '广州南站') ? <>
                                            <div className="form-group">
                                                <div className='title1'>{t('p2-【选填】请填写车次号：')}</div>
                                                <input type="input" name="fc_cch"
                                                    value={values.fc_cch}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className='form-control form-control-home'
                                                />
                                                <div className='xiao1'>{t('p2-届时')}</div>
                                            </div>
                                        </> : null
                                    }




                                    <div className='title2-kuang'>
                                        <span className='title2'>{t('p2-客房安排')}</span>
                                        <div className='xiao2'>{t('p2-预留')}</div>
                                    </div>
                                    {
                                        lang === 'zh' ? <>
                                            <div className="form-group" style={{ display: 'flex' }}>

                                                <div >{t('p2-入住需求')}</div>
                                                <div >
                                                    <label className="radio-inline baseline-input2" >
                                                        <input
                                                            type="radio"
                                                            name="rzxq"
                                                            value='1'
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />{t('p2-自行安排')}
                                                    </label>
                                                    <label className="radio-inline baseline-input2" >
                                                        <input
                                                            type="radio"
                                                            name="rzxq"
                                                            value='2'
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />{t('p2-主办方安排')}<span style={{ fontSize: '12px' }}>{t('p2-酒店')}</span>
                                                    </label>
                                                </div>

                                            </div>
                                        </> : <>
                                            <div className="form-group" >

                                                <div >{t('p2-入住需求')}</div>
                                                <div style={{marginTop:'15px'}}>
                                                    <label className="radio-inline baseline-input room-radio1" >
                                                        <input
                                                            type="radio"
                                                            name="rzxq"
                                                            value='1'
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />{t('p2-自行安排')}
                                                    </label>
                                                    <label className="radio-inline baseline-input room-radio2" >
                                                        <input
                                                            type="radio"
                                                            name="rzxq"
                                                            value='2'
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />{t('p2-主办方安排')}<span style={{ fontSize: '18px' }}>{t('p2-酒店')}</span>
                                                    </label>
                                                </div>

                                            </div>
                                        </>
                                    }

                                    {errors.rzxq && touched.rzxq ? (
                                        <div className='err'>{errors.rzxq}</div>
                                    ) : null}
                                    {
                                        values.rzxq === '2' ? <>
                                            {
                                                lang === 'zh' ? <>
                                                    <div className="form-group">
                                                        <div className='title1'>{t('p2-入住时间')}</div>
                                                        <div className=' chooseData' style={{ display: 'flex' }}>
                                                            <div style={{ width: '90px', marginLeft: '50px' }}>2023</div>
                                                            <div style={{ width: '110px' }}>{t("YEAR")} </div>
                                                            <div style={{ width: '80px' }}>5</div>
                                                            <div style={{ width: '80px' }}>{t("MONTH")}</div>
                                                            <div className="jiantou_kuang" >
                                                                <img src="/img/jiantou.png" alt="" className='jiantou' />
                                                                <select name="ruzhu_ri"
                                                                    style={{ width: '110px' }}
                                                                    value={values.ruzhu_ri}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className={errors.ruzhu_ri && touched.ruzhu_ri ?
                                                                        "input-error form-control form-control-home" : "form-control form-control-home"}>
                                                                    <option value=''></option>
                                                                    <option value='24'>24</option>
                                                                    <option value='25'>25</option>
                                                                </select>
                                                            </div>
                                                            <div style={{ width: '50px', textAlign: 'right' }}>{t("DATE")}
                                                            </div>
                                                        </div>
                                                        {errors.ruzhu_ri && touched.ruzhu_ri ? (
                                                            <div className='err'>{errors.ruzhu_ri}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="form-group">
                                                        <div className='title1'>{t('p2-退房时间')}</div>
                                                        <div className=' chooseData' style={{ display: 'flex' }}>
                                                            <div style={{ width: '90px', marginLeft: '50px' }}>2023</div>
                                                            <div style={{ width: '110px' }}>{t("YEAR")} </div>
                                                            <div style={{ width: '80px' }}>5</div>
                                                            <div style={{ width: '80px' }}>{t("MONTH")}</div>
                                                            <div className="jiantou_kuang" >
                                                                <img src="/img/jiantou.png" alt="" className='jiantou' />
                                                                <select name="tf_ri"
                                                                    style={{ width: '110px' }}
                                                                    value={values.tf_ri}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className={errors.tf_ri && touched.tf_ri ?
                                                                        "input-error form-control form-control-home" : "form-control form-control-home"}>
                                                                    <option value=''></option>
                                                                    <option value='25'>25</option>
                                                                    <option value='26'>26</option>
                                                                </select>
                                                            </div>
                                                            <div style={{ width: '50px', textAlign: 'right' }}>{t("DATE")}
                                                            </div>
                                                        </div>
                                                        {errors.tf_ri && touched.tf_ri ? (
                                                            <div className='err'>{errors.tf_ri}</div>
                                                        ) : null}
                                                    </div>
                                                </> : <>
                                                    <div className='form-group'>
                                                        <div style={{ display: 'flex' }}>
                                                            <div className='title1' style={{ width: '125px' }}>{t('p2-入住时间')}</div>Date :
                                                        </div>

                                                        <div className=' chooseData' style={{ display: 'flex' }}>
                                                            <div style={{ width: '140px' }}>
                                                                <div className="jiantou_kuang" style={{ width: '105px' }}>
                                                                    <img src="/img/jiantou.png" alt="" className='jiantou' />
                                                                    <select name="ruzhu_ri"
                                                                        style={{ width: '110px' }}
                                                                        value={values.ruzhu_ri}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.ruzhu_ri && touched.ruzhu_ri ?
                                                                            "input-error form-control form-control-home" : "form-control form-control-home"}>
                                                                        <option value=''></option>
                                                                        <option value='24'>24</option>
                                                                        <option value='25'>25</option>


                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="" style={{ width: '190px' }}>{t("DATE")}
                                                            </div>
                                                            <div className="" style={{ width: '180px' }}>May</div>
                                                            <div className="" style={{ width: '60px' }}>2023</div>
                                                        </div>
                                                        {errors.ruzhu_ri && touched.ruzhu_ri ? (
                                                            <div className='err'>{errors.ruzhu_ri}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className='form-group'>
                                                        <div style={{ display: 'flex' }}>
                                                            <div className='title1' style={{ width: '125px' }}>{t('p2-退房时间')}</div>Date :
                                                        </div>

                                                        <div className=' chooseData' style={{ display: 'flex' }}>
                                                            <div style={{ width: '140px' }}>
                                                                <div className="jiantou_kuang" style={{ width: '105px' }}>
                                                                    <img src="/img/jiantou.png" alt="" className='jiantou' />
                                                                    <select name="tf_ri"
                                                                        style={{ width: '110px' }}
                                                                        value={values.tf_ri}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={errors.tf_ri && touched.tf_ri ?
                                                                            "input-error form-control form-control-home" : "form-control form-control-home"}>
                                                                        <option value=''></option>
                                                                        <option value='25'>25</option>
                                                                        <option value='26'>26</option>


                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="" style={{ width: '190px' }}>{t("DATE")}
                                                            </div>
                                                            <div className="" style={{ width: '180px' }}>May</div>
                                                            <div className="" style={{ width: '60px' }}>2023</div>
                                                        </div>
                                                        {errors.tf_ri && touched.tf_ri ? (
                                                            <div className='err'>{errors.tf_ri}</div>
                                                        ) : null}
                                                    </div>
                                                </>
                                            }

                                        </> : null
                                    }

                                    {/* <div className='title2-kuang'>
                                        <div className='title2'>同声传译</div>
                                    </div>

                                    <div className='title1'>【选填】主会同声传译需求：</div>
                                    <div className="jiantou_kuang" >
                                        <img src="/img/jiantou.png" alt="" className='jiantou2' />
                                        <select name="trans"
                                            value={values.trans}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={errors.trans && touched.trans ?
                                                "input-error form-control form-control-home" : "form-control form-control-home"}>
                                            <option value=''></option>
                                            <option value='日语'>日语</option>
                                            <option value='英语'>英语</option>
                                        </select>
                                    </div>
                                    {errors.trans && touched.trans ? (
                                        <div className='err'>{errors.trans}</div>
                                    ) : null} */}
                                </div>

                                <img src={lang === 'zh' ? "/img/bg-zh-xia.png" : "/img/bg-en-xia.png"} alt="" style={{ width: '100%' }} />


                                <div style={{ padding: '0 80px', backgroundImage: "linear-gradient(to right, rgb(216 227 237), rgb(218 224 236), rgb(220 229 239))", paddingBottom: '10px', marginBottom: '-25px' }}>
                                    {submitItem(values, handleChange, handleBlur, errors, touched)}
                                </div>


                            </Form>
                        )}
                    </Formik>
                </div>


            </div>
        )

    }
    return (
        <div>
            {
                login ? homeItem() : <Login setLogin={setLogin} infoHandle={infoHandle} />
            }
        </div>


    );
}

export default Home;

