import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react'

const pageApi2 = createApi({
    reducerPath: 'pageApi2',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://h5.aiweber.cn/wechat/v2'
    }),
    tagTypes: 'students',
    endpoints(build) {
        return {

            sms: build.mutation({
                query(data) {
                    return {
                        url: 'sms_code',
                        method: 'post',
                        body: {...data}
                    }
                },
            }),
            login: build.mutation({
                query(data) {
                    return {
                        url: 'login',
                        method: 'post',
                        body: {...data}
                    }
                },
            }),
            info: build.mutation({
                query(data) {
                    return {
                        url: 'info',
                        method: 'post',
                        body: {...data}
                    }
                },
            }),
            update: build.mutation({
                query(data) {
                    return {
                        url: 'update',
                        method: 'post',
                        body: {...data}
                    }
                },
            }),
            times: build.mutation({
                query(data) {
                    return {
                        url: 'times',
                        method: 'post',
                        body: {...data}
                    }
                },
            }),

        }
    }
})

export const {
    useSmsMutation,
    useLoginMutation,
    useInfoMutation,
    useUpdateMutation,
    useTimesMutation,
} = pageApi2

export default pageApi2