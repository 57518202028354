import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react'

const pageApi = createApi({
    reducerPath: 'pageApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://h5.aiweber.cn/wechat'
    }),
    tagTypes: 'students',
    endpoints(build) {
        return {

            register: build.mutation({
                query(data) {
                    return {
                        url: 'register',
                        method: 'post',
                        body: {...data}
                    }
                },
            }),

        }
    }
})

export const {
    useRegisterMutation
} = pageApi

export default pageApi