import React from 'react';
import Routes from './router';
const App = () => {
  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
