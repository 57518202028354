import { configureStore } from "@reduxjs/toolkit";
import pageSlice from "./pageSlice";
import pageApi from "./pageApi";
import shareApi from "./shareApi";
import pageApi2 from "./pageApi2";

const store=configureStore({
    reducer:{
        pageSlice:pageSlice.reducer,
        pageApi:pageApi.reducer,
        pageApi2:pageApi2.reducer,
        shareApi:shareApi.reducer
    },
    middleware:(get)=>
    get().concat(
        pageApi.middleware,
        pageApi2.middleware,
        shareApi.middleware
    )
})

export default store