import React from 'react';
import { useState } from 'react';
import './index.css'
import { useEffect } from 'react';

const Index = () => {
    const [lang, setLang] = useState('zh')
    useEffect(() => {
        document.title = '2023年小鹏汽车全球合作伙伴大会-会议导览'
    }, [])
    const [page, setPage] = useState(1)

    const guideItem = () => {
        return <div>
            {
                lang==='zh'?<img src="/img/index-guide-5-23.png" alt="" width={'100%'} />:<img src="/img/index-guide-en-5-24.png" alt="" width={'100%'} />
            }
        </div>
    }
    const meetingItem = () => {
        return <div>
            
            {
                lang==='zh'?<img src="/img/index-meeting-5-22.png" alt="" width={'100%'} />:<img src="/img/index-meeting-en-5-24.png" alt="" width={'100%'} />
            }
        </div>
    }
    const mapItem = () => {
        return <div>
            
            {
                lang==='zh'?<img src="/img/index-map.png" alt="" width={'100%'} />:<img src="/img/index-map-en-5-24.png" alt="" width={'100%'} />
            }
        </div>
    }

    const direct = () => {
        window.location.href = "https://as.alltuu.com/album/1111317142/?from=appmessage";
    }


    return (
        <div>
            <div style={{
                fontWeight: '400', maxWidth: '750px', margin: '0 auto', position: 'relative', fontSize: '24px', padding: 0,

            }} className='container'>

                <div style={{ marginBottom: '30px',position:'absolute',top:'755px',left:'55px' }}>
                    <label className="radio-inline-lang radio-inline" >
                        <input
                            type="radio"
                            name="lang"
                            value='zh'
                            onChange={(e) => {
                               
                                setLang('zh')
                            }}
                            checked={lang === 'zh'}
                        /> 简体中文
                    </label>
                    <label className="radio-inline" >
                        <input
                            type="radio"
                            name="lang"
                            value='en'
                            onChange={(e) => {
                               
                                setLang('en')
                            }}
                            checked={lang !== 'zh'}
                        /> English
                    </label>
                </div>
                <img src="/img/index-head.png" alt="" width={'100%'} />
                <div style={{ marginBottom: '90px', backgroundSize: '100% 100%', backgroundImage: 'linear-gradient( #dee8f1, transparent)' }}>
                    {page === 1 ? guideItem() : null}
                    {page === 2 ? meetingItem() : null}
                    {page === 3 ? mapItem() : null}

                </div>
                <table style={{ width: '100%', position: 'fixed', bottom: '0', backgroundColor: '#ebf0f6', maxWidth: '750px', margin: '0 auto', fontWeight: '500' }} border={'2px'}>
                    <tr style={{ textAlign: 'center', lineHeight: '90px' }}>
                    {
                        lang==='zh'?<>
                        <td onClick={() => { setPage(1) }} style={page === 1 ? { color: '#333' } : { color: '#999' }}>参会指南</td>
                        <td onClick={() => { setPage(2) }} style={page === 2 ? { color: '#333' } : { color: '#999' }}>会议日程</td>
                        <td onClick={() => { setPage(3) }} style={page === 3 ? { color: '#333' } : { color: '#999' }}>会场地图</td>
                        <td onClick={() => direct()} ><a style={page === 4 ? { color: '#333' } : { color: '#999' }}>精彩瞬间</a></td>
                        </>:<>
                        <td onClick={() => { setPage(1) }} style={page === 1 ? { color: '#333' } : { color: '#999' }}>GUIDE</td>
                        <td onClick={() => { setPage(2) }} style={page === 2 ? { color: '#333' } : { color: '#999' }}>AGENDA</td>
                        <td onClick={() => { setPage(3) }} style={page === 3 ? { color: '#333' } : { color: '#999' }}>MAP</td>
                        <td onClick={() => direct()} ><a style={page === 4 ? { color: '#333' } : { color: '#999' }}>PHOTO</a></td>
                        </>
                    }
                        
                    </tr>
                </table>
            </div>
        </div>
    );
}

export default Index;
