import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react'

const shareApi = createApi({
    reducerPath: 'shareApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://h5.aiweber.cn'
    }),
    tagTypes: 'students',
    endpoints(build) {
        return {

            share: build.mutation({
                query(data) {
                    return {
                        url: 'jssdk',
                        method: 'post',
                        body: {"url":data}
                    }
                },
            }),

        }
    }
})

export const {
    useShareMutation
} = shareApi

export default shareApi