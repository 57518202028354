import { createSlice } from "@reduxjs/toolkit";

const pageSlice=createSlice({
    name:'pageSlice',
    initialState:{
        name:'zhubajie'
    },
    reducers:{
        changeName(state,action){
            state.name=action.payload
        }
    }
})

export const {
    changeName
}=pageSlice.actions

export default pageSlice